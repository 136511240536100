html {
    position: relative;
    min-height: 100%;
}

body {
    margin: 0 0 100px;
}

.my-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    overflow:hidden;
}

.btn:focus {
  outline: 0;
}

